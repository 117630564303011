import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {FlexboxGrid, Form, InputPicker} from "rsuite"
import {CurrencyInput, DateSelect, EntityPickerWithSelect, FormItem} from "../../form/rsuite-form"
import {AccountSelect} from "./form-elements"
import {
    changeAccount,
    changeAmount,
    changeContraAccount,
    changeTax,
    selectBooking,
    selectErrors,
    selectTaxAccount,
    selectTaxChoices,
    setBooking
} from "../store/editor-slice"
import {selectOrganization} from "../store/entries-slice";

export default function EntryEditorSimple() {

    useEffect(() => {
        document.getElementById('amount')?.focus()
    }, []);

    const dispatch = useDispatch()
    const booking = useSelector(selectBooking)
    const errors = useSelector(selectErrors)
    const organization = useSelector(selectOrganization)
    const taxChoices = useSelector(selectTaxChoices)
    const taxAccount = useSelector(selectTaxAccount) ?? ""

    if (3 < booking.parts.length || (3 === booking.parts.length && !taxAccount)) {
        return <p>Eine Split-Buchung kann nicht als einfache Buchung bearbeitet werden.</p>
    }

    const {parts, bookDate, receiptDate, receiptNumber, subject, costObject, reference} = booking
    const formError = !errors ? undefined : {}

    if (errors) {
        for (const field of ["bookDate", "receiptDate", "receiptNumber", "tax", "subject", "costObject", "reference"]) {
            if (errors[field]?.errors) {
                formError[field] = errors[field].errors.join(" ");
            }
        }
    }

    const account = parts[0].account,
        contraAccount = parts[1].account,
        amount = {
            amount: (!!booking.parts[0]?.cr ? -1 : 1) * Math.max(
                Math.abs((booking.parts[0]?.cr ?? 0) - (booking.parts[0]?.dr ?? 0)),
                Math.abs((booking.parts[1]?.cr ?? 0) - (booking.parts[1]?.dr ?? 0))
            ),
            currency: account?.currency ?? "EUR"
        }

    if (formError) {
        if (errors.parts.children[0].children.account.errors) {
            formError.account = errors.parts.children[0].children.account.errors.join(" ");
        }
        if (errors.parts.children[1].children.account.errors) {
            formError.contraAccount = errors.parts.children[1].children.account.errors.join(" ");
        }
        if (errors.parts.children[0].children.dr.errors || errors.parts.children[1].children.cr.errors) {
            formError.amount = [].concat(errors.parts.children[0].children.dr.errors, errors.parts.children[1].children.cr.errors).join(" ");
        }
    }

    const formValue = {
        account,
        contraAccount,
        bookDate: bookDate == null ? null : new Date(bookDate),
        receiptDate: receiptDate == null ? null : new Date(receiptDate),
        receiptNumber,
        amount: !!amount?.amount ? amount : null,
        tax: taxAccount,
        subject,
        costObject,
        reference
    };

    const onChange = ({bookDate, receiptDate, receiptNumber, subject, costObject, reference}) => {
        dispatch(setBooking({
            ...booking,
            bookDate: bookDate?.toISOString(),
            receiptDate: receiptDate?.toISOString(),
            receiptNumber,
            subject,
            costObject,
            reference,
        }));
    }
    return (
        <Form fluid formValue={formValue} formError={formError} onChange={onChange}>
            <FlexboxGrid>

                <FormItem field="amount" label="Umsatz" accepter={CurrencyInput}
                          onChange={({amount}) => dispatch(changeAmount(amount))}/>
                <FormItem field="tax" label="Steuer" accepter={InputPicker} data={taxChoices} cleanable={false} block
                          onChange={tax => dispatch(changeTax(tax))}/>

                <FormItem field="account" label="Soll-Konto" accepter={AccountSelect} organization={organization}
                          onChange={account => dispatch(changeAccount(account))}/>
                <FormItem field="contraAccount" label="Haben-Konto" accepter={AccountSelect} organization={organization}
                          onChange={account => dispatch(changeContraAccount(account))}/>
                <FormItem field="receiptNumber" label="Belegnr."/>
                <FormItem field="bookDate" label="Leistungs-/Buchdatum" accepter={DateSelect} cleanable={false}/>
                <FormItem field="receiptDate" label="Belegdatum" accepter={DateSelect} cleanable={false}/>

                <FormItem field="subject" label="Buchungstext" col={{xs: 24, sm: 16, md: 12, lg: 8}}/>
                <FormItem field="costObject" label="Kostenträger" accepter={EntityPickerWithSelect}
                          modelId="accounting/cost-object" col={{xs: 12, sm: 8, md: 6, lg: 4}}/>
                <FormItem field="reference" label="Referenz" accepter={EntityPickerWithSelect}
                          modelId="accounting/booking-reference" col={{xs: 12, sm: 8, md: 6, lg: 4}}/>
            </FlexboxGrid>
        </Form>
    );
}
